@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');

a{
    color: #555555
}
.dowload-section a {
    text-decoration: none;
    padding-left: 5px;
}
.dowload-section {
    text-align: center;
    padding: 5px;
    font-weight: 600;
    border-left: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
}
.dowload-section:hover{
    background-color: rgb(201, 200, 200);
}
.dowload-section i{
    color: yellowgreen;
}
.compount-details-container{
    width: 95%;
    margin: auto;
    padding-top: 100px;
    padding-bottom: 50px;
}
table{
    border-spacing: 0;
}
.com-table-container{
    width: 100%;
    padding-top: 10px;
}
.com-table-container th{
    padding: 5px;
    text-align: left;
    padding-left: 10px;
}
.com-table-container td{
    padding: 5px;
}  

.table-row-title{
    background-color: #555555;
    color: #F0F0F0;
    font-weight: normal;
    text-shadow: black 0px -1px 0px;
}
.compound-heading{
    width: 15%;
    color: #555555;
    background-color: #F9F9F9;
    border-left: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}

.compound-details{
    border-left: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
}
.compound-details img {
    float: left;
    width:  100%;
    height: 100%;
    object-fit:cover;
}
.compound-name h1{
    font-size: 30px;
    font-family: 'Roboto Serif', serif;
    font-weight: 600;
    padding-bottom: 5px;
}
.compound-name hr{
    color: #ddd;
}
.compound-name{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.compound-name button{
    padding: 10px;
    height: 50%;
    background-color: rgb(9, 141, 9);
    border: 1px solid grey;
    border-radius: 3px;
    color: #F0F0F0;
    cursor: pointer;
}
.com-table-container .compound-heading h4{
    font-family: 'Roboto Serif', serif;
}
.compound-name h1 span{
    font-weight: 400;
    color: #555555;
    padding-right: 12px;
}