.Login{
    
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #4070f4;
    font-family: 'Poppins', sans-serif;
}

.Login h1{
    padding: 15px;
}