.navbar {
    font-size: 1.2rem;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
}
.colorChange{
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
    height: 65px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 1.2rem;
}
.navbar-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 80px;
    max-width: 1500px;
}
.navbar-container ul li{
    list-style:none;
    position: relative;
    display: inline-block;
    padding: 8px 16px;
    transition: transform .2s;
}
.navbar-container ul li .nav-links{
    text-decoration: none;
    color: white;
    font-size: 1rem;
}
.navbar-container ul li::after{
    content:'';
    height:2px;
    width: 0;
    background: #31c531;   
    display:block;
    margin: auto;
    transition: 0.3s;
}
.navbar-container ul li:hover::after{
    width: 100%;
}
.navbar-container li:hover{
    transform: scale(1.2);
}

.nav-item{
    text-transform: uppercase;
}

.navbar-logo {
    color: rgb(255, 255, 255);
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
}

.fa-pagelines {
    margin-left: 0.5rem;
    font-size: 1.8rem;
    color: yellowgreen;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 60vw;
    justify-content: end;
    margin-right: 2rem;
}


.fa-bars {
    color: #fff;
}

.menu-icon {
    display: none;
}

.navbar-logo{
    font-weight: 800;
}


@media screen and (max-width: 970px) {
    .navbar {
        background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.2rem;
        position: sticky;
        top: 0;
        z-index: 999;
        position: fixed;
    }
    .nav-menu {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding-top: 20px;
        width: 100%;
        height: 90vh;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
        
    }

    .nav-menu.active {
        background: rgb(36, 34, 34);
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }

    .nav-links {
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
    }

    .nav-links:hover {
        color: #242424;
        border-radius: 0;
    }
    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }

    .fa-times {
        color: #fff;
        font-size: 2rem;
    }
    
}