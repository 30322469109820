@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');
.home-main{
    width: 90%;
    margin: auto;
    
}
.content-col{
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);;
    border-radius: 10px;
    margin-bottom: 5%;
    box-sizing:border-box;
    transition: 0.5s;
    text-align:justify;
    font-size: large;
    padding: 50px;
    width: 100%;
    margin: auto;
    
}
.content-col p{
    font-family: 'League Gothic', sans-serif;
    color: rgb(90, 88, 88);
}

.HMC-Title h2{
    text-align: center;
    font-size: 40px;
    font-weight: 600;
    color: rgb(66, 66, 66);
    padding-bottom: 25px;
    cursor: pointer;
    font-family: 'Rowdies', cursive;
}
.HMC-Title hr{
    width: 10%;
    margin: auto;
    height: 5px;
    border-radius: 3px;
    background-color: #28772f;
    transition: 0.3s;
}

.HMC-Title:hover hr{
    width: 20%;
}
.row{
    width: 95%;
    margin: auto;
    padding-top: 30px;
    display: flex;
    justify-content: space-between;
}

.img-col{
    border-radius: 10px;
    margin-bottom: 30px;
    position: relative;
    flex-basis: 32%;
    overflow: hidden;
    padding-top: 0px;
}
.img-col img{
    width: 100%;
    display: block;
}
.layer{
    background: transparent;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    transition: 0.55s;

}
.layer:hover{
    background: rgb(154, 205, 50 ,0.8);
    cursor: pointer;
}
.layer h3{
    width: 100%;
    font-weight: 500;
    color: rgb(255, 255, 255);
    font-weight: bold;
    font-size: 26px;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    transition: 0.5s;
    opacity: 0;
    text-align: center;
    padding: 10px;
}
.layer:hover h3{
    bottom:40%;
    opacity: 1;
}

@media(max-width: 800px){
    .home-main{
        width: 95%;
    }
    .row{
        flex-direction:column;
    }
    .content-col{
        padding: 25px;
    }
}