@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

*{
    font-family: 'Roboto', sans-serif;
}

.footer-container{
    background-color: #212221;
    height: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
}

.footer-row{
    display: flex;
    justify-content:space-evenly;
    
}
.footer-col{
    padding: 50px 100px;
}
.footer-col h1{
    text-align:left;
    padding-left: 10px;
    color: #1fa329;
}
.footer-col p{
    font-size: medium;
    padding: 10px;
    color: white;
}
.footer-col Link{
    text-decoration: none;
}
.cpy-ryt{
    padding-top: 7px;
    margin: auto;
    border-top: rgb(99, 94, 94) solid 1px;
    width: 80%;
    text-decoration: none;
    color: white;
    text-align: center;
}
.addressContainer{
    display: flex;
}
.contactBox h1{
    text-align: center;
    padding-bottom: 5px;
}


@media(max-width: 800px){
    .footer-row{
        flex-direction:column;
        text-align:left;
        padding-top: 10px;
    }
    .footer-col p{
        justify-content: left;
        text-align: left;
        padding-top: 10px;
    }
    .footer-col h1{
        text-align: left;
    }
    .footer-col{
        padding: 30px 70px;
    }
}