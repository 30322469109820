@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.statistics{
    background-image: url("../Images/interlaced.png");
}
.statistics-header
{
    min-height: 50vh;
    width: 100%;
    background-image: linear-gradient(rgba(4,9,30,0.7),rgba(4,9,30,0.7)),url("../Images/Backdrop1.jpg");
    background-position:top;
    background-size: cover;
    position: relative;
}

.statistics-heading{
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: poppins,sans-serif;
    text-align: center;
    color: rgb(255, 255, 255);
    position:absolute;
    width: 80%;
    top:50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.statistics-heading h1{
    font-size: 62px;    
    text-align: center;
}
.statistics-heading h1 span{
    color: #1fa329;
}
.statistics-content{

    margin: 100px auto;
    width: 90%;
    text-align: justify;
    color: black;
    background-position: center;
    background-size: cover;
    padding: 30px 50px;
    border-radius: 10px;
}
@media(max-width: 960px){
    .statistics-heading h1{
        font-size: 40px;
    }
    .statistics-content{
        width: 100%;
        margin: 70px auto;
        padding: 20px 40px;
    }

    
}

.graph {
    background-color: white;
    padding: 25px;
    margin-bottom: 20px;
}

.graph-title {
    margin-bottom: 25px;
}