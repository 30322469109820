@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
*{
    font-family: "Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.row{
    padding-top:30px;
    display: flex;
    justify-content: space-between;
}
.aboutus{
    width: 90%;
    margin: auto;
    padding-top: 80px;
    padding-bottom: 50px;
}
.about-col{
    flex-basis: 48%;
    padding: 30px 2px;    
}
.about-col #img{
    width:90%;
    border-radius: 5px;
}
.about-col h1{
    color: white;
    border-bottom: solid 2px #7a7a7a;
    font-size: 30px;
    padding-bottom: 3px;
}
.about-col h1 span{
    color: #1fa329;
}
.about-col p{
    padding: 30px 0 25px;
    font-size: 17px;
    color: white;
    text-align: justify;
}
.hero-btn{
    display: inline-block;
    text-decoration: none;
    color: white;
    border: 1px white solid;
    padding: 12px 34px;
    background: transparent;
    position: relative;
    cursor: pointer;
    border-radius: 3px;
}
.hero-btn:hover{
    border: 1px solid #1fa329;
    background: #1fa329;
    transition: 0.3s;
}
.red-btn{
    border: 1px solid #1fa329;
    background: transparent;
    color: #1fa329;
}
.red-btn:hover{
    color:white;
}

@media(max-width: 800px){
    .row{
        flex-direction:column;
    }
}